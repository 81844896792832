<template>
  <div>
    <div v-if="loading">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="spinner-border" role="status"></div>
        <p class="text-center mt-2"><strong>Loading...</strong></p>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 col-xl-8 justify-content-between">
          <div class="card">
            <div class="card-header justify-content-between">
              <h4 class="card-header-title">Partner Information</h4>
              <b-button
                v-if="userHasPermission('partners010')"
                style="
                  color: #4848ed;
                  background-color: white;
                  border-color: #4848ed;
                  font-weight: bold;
                "
                @click="showConfig()"
                >Payout configuration</b-button
              >
            </div>
            <div v-if="partnerData" class="card-body px-3 py-2">
              <div class="list-group list-group-flush mt-2 mb-4">
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Name</span>
                    </div>
                    <div class="col-auto">
                      <span v-if="partnerData?.owner" class="">{{
                        `${partnerData?.owner?.fname} ${partnerData?.owner?.lname}`
                      }}</span>
                      <span v-else class="">N/A</span>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase"
                        >Phone Number</span
                      >
                    </div>
                    <div class="col-auto">
                      <span class="">{{
                        partnerData?.owner?.phone ?? 'N/A'
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase"
                        >Email Address</span
                      >
                    </div>
                    <div class="col-auto">
                      <span class="">{{
                        partnerData?.owner?.email ?? 'N/A'
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">AVATAR</span>
                    </div>
                    <div class="col-auto">
                      <user-avatar
                        :user="{
                          fname: partnerData.owner.fname,
                          lname: partnerData.owner.lname,
                          avatar: partnerData.owner.avatar,
                        }"
                        size="sm"
                      >
                      </user-avatar>
                    </div>
                  </div>
                </div>

                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">Company</span>
                    </div>
                    <div class="col-auto">
                      <span class="">{{ partnerData?.company_name }}</span>
                    </div>
                  </div>
                </div>

                <!-- <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase">CAR OWNER</span>
                    </div>
                    <div class="col-auto">
                      <span class="">{{
                        partnerData?.owner?.car_owner === '0' ? 'No' : 'Yes'
                      }}</span>
                    </div>
                  </div>
                </div> -->

                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase"
                        >Date Created</span
                      >
                    </div>
                    <div class="col-auto">
                      {{ partnerData?.created_at | date('MMM do, yyyy') }}
                    </div>
                  </div>
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted text-uppercase"> Status</span>
                    </div>
                    <div class="col-auto">
                      <span
                        :class="[
                          partnerData?.status === 'active'
                            ? 'text-success'
                            : partnerData?.status === 'inactive'
                            ? 'text-danger'
                            : partnerData?.status === 'pending'
                            ? 'text-info'
                            : '',
                        ]"
                      >
                        {{ partnerData?.status ?? 'N/A' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header justify-content-between">
              <h4 class="card-header-title">KYC Information</h4>
            </div>
            <div v-if="partnerData" class="card-body px-3 py-2">
              <div
                style="background-color: #f8f8f8; border-radius: 5px"
                class="p-3 font-weight-bold"
              >
                <h5 class="">IDENTITY</h5>
              </div>
              <div
                v-if="
                  partnerIdentityVerification?.data?.identity !==
                  'not-submitted'
                "
              >
                <div class="list-group list-group-flush mt-2">
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <span class="text-muted text-uppercase"
                          >MEANS OF IDENTIFICATION</span
                        >
                      </div>
                      <div class="col-auto">
                        <span v-if="partnerData?.owner" class="">{{
                          partnerIdentityVerification?.data?.identity?.document_type?.toUpperCase() ??
                          'N/A'
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <span class="text-muted text-uppercase"
                          >DOCUMENT NUMBER</span
                        >
                      </div>
                      <div class="col-auto">
                        <span class="">{{
                          partnerIdentityVerification?.data?.identity
                            ?.document_id ?? 'N/A'
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <span class="text-muted text-uppercase"
                          >DATE OF BIRTH</span
                        >
                      </div>
                      <div class="col-auto">
                        <span class="">{{
                          partnerIdentityVerification?.data?.identity?.dob ??
                          'N/A'
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <span class="text-muted text-uppercase">STATUS</span>
                      </div>
                      <div
                        class="col-auto"
                        v-if="
                          partnerIdentityVerification?.data?.identity
                            ?.status === 'completed'
                        "
                      >
                        <span style="color: green">Verified</span>
                      </div>
                      <div class="col-auto" v-else>
                        <b-button
                          v-if="userHasPermission('partners007')"
                          class="font-weight-bold"
                          @click="
                            handleVerification(
                              'identity',
                              partnerIdentityVerification?.data?.identity
                            )
                          "
                          style="background-color: black; color: white"
                          variant=""
                          size="sm"
                        >
                          {{ isVerifying ? 'processing' : 'Verify' }}
                        </b-button>
                        <span v-else>{{
                          isVerifying ? 'Processing' : 'UnVerified'
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-2 text-muted py-3" v-else>
                Partner Identity information not submitted
              </div>
              <div
                style="background-color: #f8f8f8; border-radius: 5px"
                class="p-3 font-weight-bold"
              >
                <h5 class="">ADDRESS</h5>
              </div>
              <div
                v-if="
                  partnerIdentityVerification?.data?.address !== 'not-submitted'
                "
              >
                <div class="list-group list-group-flush mt-2 mb-4">
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <span class="text-muted text-uppercase">ADDRESS</span>
                      </div>

                      <div class="col-auto text-muted">
                        <!-- {{
                          partnerIdentityVerification?.data?.address
                            ?.address === null
                            ? 'No address available'
                            : partnerIdentityVerification?.data?.address?.address.split?.(
                                '"'
                              )[1]
                        }} -->
                        {{
                          partnerIdentityVerification?.data?.address?.address === null
                            ? 'No address available'
                            : partnerIdentityVerification?.data?.address?.address
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <span class="text-muted text-uppercase">DOCUMENT</span>
                      </div>
                      <div class="col-auto">
                        <LightGallery
                          :images="images"
                          :index="index"
                          :disable-scroll="true"
                          @close="index = null"
                        />
                        <small
                          v-for="(
                            eachDocument, documentIndex
                          ) in formattedDocumentsArray"
                          :key="documentIndex"
                          @click="index = documentIndex"
                          style="
                            color: #4848ed;
                            text-decoration: underline;
                            cursor: pointer;
                          "
                          >View document {{  }}</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <span class="text-muted text-uppercase">STATUS</span>
                      </div>
                      <div
                        class="col-auto"
                        v-if="userHasPermission('partners008')"
                      >
                        <div
                          v-if="showUpdateForm"
                          class="d-flex justify-content-between align-items-center"
                        >
                          <v-select
                            v-model="address"
                            class="mr-2"
                            :options="addressOptions"
                            label="label"
                            placeholder="Select address"
                            :reduce="(address) => address.value"
                            style="width: 200px"
                          >
                          </v-select>
                          <b-button
                            @click="
                              savingAddressUpdate(partnerIdentityVerification)
                            "
                            size="sm"
                            class="mr-2"
                            variant="success"
                            >Save Changes</b-button
                          >
                          <b-button
                            size="sm"
                            class="ml-2"
                            @click="cancelUpdate()"
                            variant="dark"
                            >Cancel</b-button
                          >
                        </div>
                        <div
                          v-if="
                            partnerIdentityVerification?.data?.address
                              ?.status == 'submitted' ||
                            partnerIdentityVerification?.data?.address
                              ?.status === 'failed'
                          "
                        >
                          <b-button
                            v-if="showUpdateButton"
                            size="sm"
                            @click="toggleUpdate()"
                            variant="primary"
                            >Update</b-button
                          >
                        </div>
                        <div class="col-auto" v-else>
                          <span style="color: green">Verified</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-2 text-muted py-3" v-else>
                Partner Address information not submitted
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-4">
          <partner-earnings
            :total-earnings="partnerEarnings?.unsettledEarnings?.amount || 0"
            :partnerData="partnerData"
            :numberOfTrips="tripCount || 0"
          >
          </partner-earnings>

          <div class="card mt-5">
            <div class="card-body">
              <div class="list-group list-group-flush">
                <div class="list-group-item pt-2 pb-3 px-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted">Total Number of Vehicles</span>
                    </div>
                    <div class="col-auto">
                      <span class="">{{ vehiclesCount || 0 }}</span>
                    </div>
                  </div>
                </div>
                <div class="list-group-item pt-2 pb-3 px-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted">Active Number of Vehicles</span>
                    </div>
                    <div class="col-auto">
                      <span class="">{{ activeVehiclesCount || 0 }}</span>
                    </div>
                  </div>
                </div>
                <div class="list-group-item pb-2 pt-3 px-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="text-muted">Total Number of Drivers</span>
                    </div>
                    <div class="col-auto">
                      <span class="">{{ driversCount || 0 }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mt-5">
            <div class="card-body">
              <div class="list-group list-group-flush">
                <div class="list-group-item pt-2 pb-3 px-0">
                  <div class="row align-items-center">
                    <div class="col d-flex flex-column">
                      <span class="block font-weight-light pb-2"
                        >Default settlement account</span
                      >
                      <div v-if="partnerEarnings?.settlementAccount">
                        <span class="block font-weight-bold"
                          >{{
                            partnerEarnings?.settlementAccount?.accountNumber
                          }}
                          -
                          {{
                            partnerEarnings?.settlementAccount?.bankName
                          }}</span
                        >
                      </div>
                      <small class="text-muted" v-else
                        >No settlement account available</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template>
      <payout-config-modal
        :show="showPayoutConfig"
        :partnerData="{}"
        @close="showPayoutConfig = false"
        @showPayout="showConfig()"
        @updatedPayout="payoutUpdated()"
      >
      </payout-config-modal>
    </template>
  </div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import PartnerEarnings from '@/views/Partners/components/PartnerEarnings'
import Swal from 'sweetalert2'
import PayoutConfigModal from '@/components/modals/PayoutConfigModal.vue'
import UserAvatar from '@/components/core/UserAvatar'

export default {
  name: 'PartnerProfile',
  components: {
    PartnerEarnings,
    PayoutConfigModal,
    UserAvatar,
  },
  props: {
    partnerId: {
      type: [Number, String],
      required: true,
    },
    accountSid: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      index: null,
      errorLoading: false,
      isVerifying: false,
      partnerData: null,
      partnerEarnings: null,
      indexRef: 0,
      visibleRef: false,
      driversCount: null,
      activeVehiclesCount: null,
      vehiclesCount: null,
      tripCount: null,
      showPayoutConfig: false,
      docUrl: '',
      partnerIdentityVerification: null,
      imgsRef: [],
      showUpdateForm: false,
      showUpdateButton: true,
      address: '',
      images: [],
      addressOptions: [
        {
          value: 'failed',
          label: 'Failed',
        },
        {
          value: 'completed',
          label: 'Approved',
        },
      ],
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },
    formattedDocumentsArray() {
      const result = [...new Set(this.images)]
      return result
    },
  },
  created() {
    this.init()
    this.payoutUpdated()
  },
  methods: {
    toggleUpdate() {
      this.showUpdateForm = true
      this.showUpdateButton = false
    },
    cancelUpdate() {
      this.showUpdateForm = false
      this.showUpdateButton = true
    },
    init() {
      this.loading = true
      this.errorLoading = false
      const fetchPartnerInfo = this.axios.get(`/v1/partners/${this.partnerId}`)
      const fetchWalletInfo = this.axios.get(
        `/cost-revenue/v1/partners/${this.accountSid}/earnings-summary`
      )
      const fetchPartnerKycInfo = this.axios.get(
        `/v1/partners/${this.accountSid}/kyc/status`
      )
      // const fetchPartnerVehiclesCount = this.axios.get(
      //   `/v1/partner/${this.partnerId}/vehicles?metadata=true&status=active&limit=0.5&page=1`
      // )

      // const fetchPartnersDriversCount = this.axios.get(
      //   `/v1/partner/${this.partnerId}/vehicle_drivers?metadata=true&limit=0.5&page=1`
      // )

      const data = Promise.allSettled([
        fetchPartnerInfo,
        fetchWalletInfo,
        fetchPartnerKycInfo,
      ])
      data
        .then((res) => {
          this.partnerData = res[0]?.value?.data ?? {}
          this.partnerEarnings = res[1]?.value?.data ?? {}
          this.tripCount =
            res[1]?.value?.data?.unsettledEarnings?.tripsCount ?? 0
			    this.partnerIdentityVerification = res[2]?.value?.data ?? {}

          this.docUrl = this.partnerIdentityVerification?.data?.address?.document_files[0]

          this.images.push(this.docUrl)
          const partnerStats = this.partnerData.stats || {
            activeVehiclesCount: 0,
            allVehiclesCount: 0,
            allDriversCount: 0,
          }

          this.vehiclesCount = partnerStats?.allVehiclesCount || 0
          this.activeVehiclesCount = partnerStats?.activeVehiclesCount || 0
          this.driversCount = partnerStats?.allDriversCount || 0
        })
        .catch(() => {
          this.errorLoading = true
        })
        .finally(() => (this.loading = false))
    },
    savingAddressUpdate(verificationData) {
      Swal.fire({
        title: 'Update Address?',
        text: 'Are you sure you want to continue?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '	#000000',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Proceed',
        preConfirm: () => {
          return this.axios
            .put(
              `/v1/partners/${this.accountSid}/identity-documents/${verificationData?.data?.address?.id}/status/${this.address}`,
              {
                user_id: this.currentUser.id,
              }
            )
            .then(() => {
              this.showUpdateForm = false
              this.showUpdateButton = true
              this.init()
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'
              this.$swal().showValidationMessage(msg)
              this.showUpdateForm = false
              this.showUpdateButton = true
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Address update was successful',
            showCloseButton: true,
          })
          this.showUpdateForm = false
          this.showUpdateButton = true
          this.init()
        }
      })
    },

    handleVerification(verificationItem, verificationObject) {
      Swal.fire({
        title: `Verify ${verificationItem}?`,
        text: 'Are you sure you want to continue?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '	#000000',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Proceed',
        preConfirm: () => {
          this.isVerifying = true
          return this.axios
            .put(
              `/v1/partners/${this.accountSid}/identity-documents/${verificationObject.id}/status/completed`,
              {
                user_id: this.currentUser.id,
              }
            )
            .then(() => {
              this.isVerifying = false
              this.init()
            })
            .catch((error) => {
              this.isVerifying = false
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'
              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Identity verified',
            'You have successfully verified this partners identity.',
            'success'
          )
          this.init()
          this.isVerifying = false
        }
      })
    },

    showConfig() {
      this.showPayoutConfig = !this.showPayoutConfig
    },

    payoutUpdated() {
      this.init()
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group {
  .list-group-item {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
</style>
